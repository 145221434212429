<template>
	<button @click="[action(), executeAction()]" @mouseenter="soundStore.playHover" :aria-label="label">
		<span class="dot"></span>
		<span class="label">{{ label }}</span>
		<span class="arrow">
			<ArrowRight />
		</span>
	</button>
</template>

<script setup>
import ArrowRight from '~/assets/svg/Arrow-Right.svg?component'
const soundStore = useSoundStore()
const props = defineProps(['label', 'action', 'target'])

const gtm = useGtm()

const executeAction = () => {

	soundStore.playClick()

	gtm.trackEvent({
		event: 'interaction',
		category: 'Call to Action',
		action: 'click',
		label: props.label,
		action: props.action
	})
}

</script>

const PI2 = Math.PI * 2

export class GlowParticle {
  constructor(x, y, radius, rgb) {
    this.x = x
    this.y = y
    this.radius = radius
    this.rgb = rgb

    const velocity = 4

    this.vx = Math.random() * velocity
    this.vy = Math.random() * velocity

    this.sinValue = Math.random()
  }

  animate(ctx, stageWidth, stageHeight) {
    this.sinValue += 0.01

    this.radius += Math.sin(this.sinValue)

    this.x += this.vx
    this.y += this.vy

    if (this.x < 0) {
      this.vx *= -1
      this.x += 10
    } else if (this.x > stageWidth) {
      this.vx *= -1
      this.x -= 10
    }

    if (this.y < 0) {
      this.vy *= -1
      this.y += 10
    } else if (this.y > stageHeight) {
      this.vy *= -1
      this.y -= 10
    }

    ctx.beginPath()

    const g = ctx.createRadialGradient(
      this.x,
      this.y,
      this.radius * 0.01,
      this.x,
      this.y,
      this.radius
    )

    g.addColorStop(0, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 1)`)
    g.addColorStop(1, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 0)`)

    ctx.fillStyle = g
    ctx.arc(this.x, this.y, this.radius, 0, PI2, false)
    ctx.fill()
  }

  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }
}

<template>
	<button @mouseenter="soundStore.playHover" @click="toggleNav" class="btn">
		<svg class="icon" viewBox="0 0 45 45" fill="none">
			<path id="top" d="M9 13L37 13" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
			<path id="middle" d="M9 23L37 23" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
			<path id="bottom" d="M9 33H37" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	</button>
</template>

<script setup>
import { gsap } from 'gsap'

const store = useGlobalStore()
const soundStore = useSoundStore()

let menuAnim

onMounted(() => {
	gsap.set("#top", { transformOrigin: "100% 50%", scaleX: .3 })
	gsap.set("#middle", { transformOrigin: "100% 50%", scaleX: .6 })
	gsap.set("#bottom", { transformOrigin: "100% 50%", scaleX: 1 })

	menuAnim = gsap.timeline({ paused: true, defaults: { duration: 1, ease: "elastic.inOut" } })
	menuAnim.to("#top", { scaleX: 1.2, rotation: -36 });
	menuAnim.to("#middle", { scaleX: 0, opacity: 0 }, "-=1");
	menuAnim.to("#bottom", { scaleX: 1.2, rotation: 36 }, "-=1");
});

const toggleNav = () => {
	if (store.menuOpen === true) {
		menuAnim.reverse()
		soundStore.playClose()
		store.closeNav()
	} else {
		store.openNav()
		soundStore.playClick()
		menuAnim.play()
	}
}

const closeNav = () => {
	if (store.menuOpen === true) {
		menuAnim.reverse()
		soundStore.playClose()
		store.closeNav()
	}
}

defineExpose({
	closeNav
})
</script>
<template>
	<header id="nav" ref="nav" :class="fixed ? 'fixed' : hidden ? 'hidden' : ''">

		<div class="grid container">

			<div class="third span-sm-2 span-xs-2 aleft">
				<NuxtLink to="/" aria-label="Back to Home">
					<AMARIcon class="logo-icon" @mouseenter="soundStore.playHover" @click="soundStore.playClick" />
				</NuxtLink>
			</div>

			<div class="thirds span-sm-10 span-xs-4 aright">

				<ButtonsAction :label="'Let’s Talk'" class="btn inverted hide-mobile" :action="store.openCalendar" />

				<NuxtLink to="https://marketing.amardesign.co.uk/meetings/amardesign/new-business" aria-label="Let's Talk"
					target="_blank" class="btn show-mobile-only">
					<CalendarIcon class="icon" />
				</NuxtLink>

				<ButtonsSound></ButtonsSound>
				<ButtonsMenu aria-label="Menu" ref="menu"></ButtonsMenu>
			</div>

		</div>

		<VerticalNav @close-nav="closeNav" />
	</header>
</template>

<script setup>
import AMARIcon from '~/assets/svg/AMAR_Icon.svg?skipsvgo'
import CalendarIcon from '~/assets/svg/Calendar.svg?skipsvgo'
import ArrowRight from '~/assets/svg/Arrow-Right.svg?component'

const nav = ref(null)
const menu = ref(null)
const fixed = ref(false)
const hidden = ref(false)
let startY = ref(0)

onMounted(() => {
	window.addEventListener('scroll', handleScroll)
});

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
});

const store = useGlobalStore()
const soundStore = useSoundStore()

const handleScroll = () => {
	var scrollY = window.scrollY
	fixed.value = true

	if (scrollY > 100) {
		menu.value.closeNav()

		if (scrollY > startY) {
			fixed.value = false
			hidden.value = true
		} else {
			fixed.value = true
			hidden.value = false
		}
		startY = scrollY
	}
}

const closeNav = () => {
	menu.value.closeNav()
}

</script>

<style lang="scss" scoped>
.logo-icon {
	fill: var(--color-primary) !important;
	filter: drop-shadow(var(--shadow-drop));
	margin-top: 5px;
}

#nav {
	position: absolute;
	width: 100%;
	z-index: 10000;
	transition: top cubic-bezier(.35, 0, 0, 1) 1s, ;

	&.hidden {
		position: fixed;
		top: -150px;
	}

	&.fixed {
		position: fixed;
		top: 0px;
	}
}
</style>
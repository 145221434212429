import { defineStore } from 'pinia'

import MainAudio from '~/assets/audio/AMAR_EmotiZen.mp3'
import ClickSound from '~/assets/audio/click_sound.mp3'
import CloseSound from '~/assets/audio/close_sound.mp3'
import HoverSound1 from '~/assets/audio/hover_sound_1.mp3'
import HoverSound2 from '~/assets/audio/hover_sound_2.mp3'
import HoverSound3 from '~/assets/audio/hover_sound_3.mp3'

export const useSoundStore = defineStore('sound', {
  state: () => ({ 
    soundActive: false,
    
    music: null,
    click_sound: null,
    close_sound: null,
    hover_sound_1: null,
    hover_sound_2: null,
    hover_sound_3: null,
   
    hover_index: 1
  }),
  
  actions: {
    openSound() {
      this.soundActive = true
      this.music = new Audio(MainAudio)
      this.hover_sound_1 = new Audio(HoverSound1)
      this.hover_sound_2 = new Audio(HoverSound2)
      this.hover_sound_3 = new Audio(HoverSound3)
      this.click_sound = new Audio(ClickSound)
      this.close_sound = new Audio(CloseSound)
    },

    closeSound() {
      this.soundActive = false
    },

    playMusic() {
      
      this.music.loop = true
      this.music.volume = .8
      this.music.play()
    },
    
    pauseMusic() {
      this.music.pause()
    },

    playHover() {
      if(this.soundActive) {

        switch(this.hover_index) {
          case 1:
            this.hover_sound_1.volume = 0.3
            this.hover_sound_1.play()
            break;
          case 2:
            this.hover_sound_2.volume = 0.3
            this.hover_sound_2.play()
            break;
          case 3:
            this.hover_sound_3.volume = 0.3
            this.hover_sound_3.play()
            break;
          default:
            this.hover_sound_1.volume = 0.3
            this.hover_sound_1.play()
        }
      }
      this.hover_index >= 3 ? this.hover_index = 1 : this.hover_index++
    },

    playClick() {
      if(this.soundActive) {
        this.click_sound.volume = .5
        this.click_sound.play()
      }
    },
    
    playClose() {
      if(this.soundActive) {
        this.close_sound.volume = .5
        this.close_sound.play()
      }
    }

  }
})
import { default as previewnuoRgVFeuEMeta } from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.1.0_rollup@2.79.1_vue@3.4.15/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue?macro=true";
import { default as _91uid_93JInuVsK1iaMeta } from "/opt/build/repo/pages/[uid].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91uid_93PYFMkDrI0uMeta } from "/opt/build/repo/pages/insights/[uid].vue?macro=true";
import { default as index6DHaoUsmVRMeta } from "/opt/build/repo/pages/insights/index.vue?macro=true";
import { default as _91uid_933Uz8y0jbcCMeta } from "/opt/build/repo/pages/services/[uid].vue?macro=true";
import { default as slice_45simulatoraGnv8AQLQlMeta } from "/opt/build/repo/pages/slice-simulator.vue?macro=true";
export default [
  {
    name: previewnuoRgVFeuEMeta?.name ?? "prismic-preview",
    path: previewnuoRgVFeuEMeta?.path ?? "/preview",
    meta: previewnuoRgVFeuEMeta || {},
    alias: previewnuoRgVFeuEMeta?.alias || [],
    redirect: previewnuoRgVFeuEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.1.0_rollup@2.79.1_vue@3.4.15/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93JInuVsK1iaMeta?.name ?? "uid",
    path: _91uid_93JInuVsK1iaMeta?.path ?? "/:uid()",
    meta: _91uid_93JInuVsK1iaMeta || {},
    alias: _91uid_93JInuVsK1iaMeta?.alias || [],
    redirect: _91uid_93JInuVsK1iaMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93PYFMkDrI0uMeta?.name ?? "insights-uid",
    path: _91uid_93PYFMkDrI0uMeta?.path ?? "/insights/:uid()",
    meta: _91uid_93PYFMkDrI0uMeta || {},
    alias: _91uid_93PYFMkDrI0uMeta?.alias || [],
    redirect: _91uid_93PYFMkDrI0uMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/insights/[uid].vue").then(m => m.default || m)
  },
  {
    name: index6DHaoUsmVRMeta?.name ?? "insights",
    path: index6DHaoUsmVRMeta?.path ?? "/insights",
    meta: index6DHaoUsmVRMeta || {},
    alias: index6DHaoUsmVRMeta?.alias || [],
    redirect: index6DHaoUsmVRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_933Uz8y0jbcCMeta?.name ?? "services-uid",
    path: _91uid_933Uz8y0jbcCMeta?.path ?? "/services/:uid()",
    meta: _91uid_933Uz8y0jbcCMeta || {},
    alias: _91uid_933Uz8y0jbcCMeta?.alias || [],
    redirect: _91uid_933Uz8y0jbcCMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/services/[uid].vue").then(m => m.default || m)
  },
  {
    name: slice_45simulatoraGnv8AQLQlMeta?.name ?? "slice-simulator",
    path: slice_45simulatoraGnv8AQLQlMeta?.path ?? "/slice-simulator",
    meta: slice_45simulatoraGnv8AQLQlMeta || {},
    alias: slice_45simulatoraGnv8AQLQlMeta?.alias || [],
    redirect: slice_45simulatoraGnv8AQLQlMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/slice-simulator.vue").then(m => m.default || m)
  }
]
import revive_payload_client_5KHbt5erSo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZlBHPtKCaq from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iv92mNoZP5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8hMevSBdHw from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.9_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.15_nuxt@3.9.3_postcss@8.4._vge5uocs77qnfqdrt5ggo7vw5y/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_BRSziLOY4o from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_mnwXJwMOQ8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_4EWy8lSFnS from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@2.79.1_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_upyoNqncLb from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_4lnPNOPGP2 from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@2.79.1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import titles_WjkeXqT7Hp from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.8_@lezer+common@1.2.1_@nuxt+devtools@1.0.8_@unhead+shared@1.8.10_@vue+co_hjhcwbzpwqcgvza66vbxqppg3i/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_ewXMCgLQVs from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.8_@lezer+common@1.2.1_@nuxt+devtools@1.0.8_@unhead+shared@1.8.10_@vue+co_hjhcwbzpwqcgvza66vbxqppg3i/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_w0HH5ADeCs from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.4_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.15_nuxt@3.9.3_pos_vy4ho6lobjxmqqlvxhxxcsuiem/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_Kn3kKRm95h from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.4_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.15_nuxt@3.9.3_pos_vy4ho6lobjxmqqlvxhxxcsuiem/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_M56N6utdhG from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.9.3_rollup@2.79.1_vue@3.4.15/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_E2GmCNym5F from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.1.0_rollup@2.79.1_vue@3.4.15/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_FUD8Pqd497 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.1.0_rollup@2.79.1_vue@3.4.15/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import templates_pwa_client_118f55ee_3zyFJsUn6c from "/opt/build/repo/.nuxt/templates.pwa.client.118f55ee.ts";
import chunk_reload_client_3rMVp1G8gy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.3_rollup@2.79.1_sass@1.70.0_vite@5.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import lordicon_client_4PZ9dgcbg7 from "/opt/build/repo/plugins/lordicon.client.js";
export default [
  revive_payload_client_5KHbt5erSo,
  unhead_ZlBHPtKCaq,
  router_iv92mNoZP5,
  _0_siteConfig_8hMevSBdHw,
  payload_client_BRSziLOY4o,
  check_outdated_build_client_mnwXJwMOQ8,
  plugin_vue3_4EWy8lSFnS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_upyoNqncLb,
  plugin_4lnPNOPGP2,
  titles_WjkeXqT7Hp,
  defaults_ewXMCgLQVs,
  siteConfig_w0HH5ADeCs,
  inferSeoMetaPlugin_Kn3kKRm95h,
  plugin_M56N6utdhG,
  plugin_client_E2GmCNym5F,
  plugin_FUD8Pqd497,
  templates_pwa_client_118f55ee_3zyFJsUn6c,
  chunk_reload_client_3rMVp1G8gy,
  lordicon_client_4PZ9dgcbg7
]
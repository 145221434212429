<template>
  <canvas ref="canvas"></canvas>
</template>

<script setup>
import { GlowParticle } from '@/scripts/glowparticles'

const canvas = ref()
const totalParticles = ref(8)

const colours = ref([
  { r: 253, g: 2, b: 120 }, // pink
  { r: 88, g: 31, b: 249 }, // blue
  { r: 69, g: 34, b: 139 }, // purple
  { r: 37, g: 27, b: 45 }, // black
])

let ctx, pixelRatio, particles, maxRadius, minRadius, stageWidth, stageHeight, curColour

const resize = () => {
  stageWidth = window.innerWidth
  stageHeight = window.innerHeight

  canvas.value.width = stageWidth * pixelRatio
  canvas.value.height = stageHeight * pixelRatio
  ctx.scale(pixelRatio, pixelRatio)

  ctx.globalCompositeOperation = 'hardlight'
  // ctx.globalCompositeOperation = 'overlay'

  createParticles()
}

  const createParticles = () => {
    curColour = 0
    particles = []

    for (let i = 0; i < totalParticles.value; i++) {
      const item = new GlowParticle(
        Math.random() * stageWidth,
        Math.random() * stageHeight,
        Math.random() * (maxRadius - minRadius) + minRadius,
        colours.value[curColour]
      )

      if (++curColour >= colours.value.length) {
        curColour = 0
      }

      particles[i] = item
    }
  }

  const animate = () => {
    window.requestAnimationFrame(animate.bind(this))
    ctx.clearRect(0, 0, stageWidth + 200, stageHeight + 200)

    for (let i = 0; i < totalParticles.value; i++) {
      const item = particles[i]
      item.animate(ctx, stageWidth, stageHeight)
    }
  }

  onMounted(() => {
    ctx = canvas.value.getContext('2d')

    pixelRatio = 1
    particles = []
    maxRadius = window.innerHeight - ( 300 / window.devicePixelRatio )
    minRadius = window.innerHeight - ( 600 / window.devicePixelRatio )

    window.addEventListener('resize', resize.bind(this), false)
    window.requestAnimationFrame(animate.bind(this))

    resize()
  })
</script>

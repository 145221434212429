<template>
  <div>
    <AppNavigation />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>

  const nuxtApp = useNuxtApp()
  const soundStore = useSoundStore()

  nuxtApp.hook("page:finish", () => {
    window.scrollTo(0, 0)
  })

  onMounted(() => {
    document.addEventListener("visibilitychange", () => {
      if(soundStore.soundActive === true) {
        if(document.visibilityState === 'hidden') {
          soundStore.pauseMusic()
        } else {
          soundStore.playMusic()
        }
      }
    });
  })

</script>

<style>
  .page-enter-active,
  .page-leave-active {
    transition: all 0.3s;
  }
  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    filter: blur(.5rem);
  }
</style>
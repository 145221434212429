<template>
	<section id="vertical-nav" :class="{ active: store.menuOpen }">

		<span class="header5">Core Services</span>
		<ul class="services">
			<li v-for="item in navigation.data.services" :key="item.name" @click="$emit('closeNav')">
				<NuxtLink :to="`/services/${item.link.uid}`" class="bganim">{{ item.name }}</NuxtLink>
			</li>
		</ul>

		<span class="header5">Links</span>
		<ul class="pages">
			<li v-for="item in navigation.data.pages" :key="item.name" @click="$emit('closeNav')">
				<NuxtLink :to="`/${item.link.uid}`">
					{{ item.name }}
				</NuxtLink>
			</li>
		</ul>

	</section>
</template>

<script setup>
const { client } = usePrismic();

const { data: navigation } = await useAsyncData("navigation", () =>
	client.getSingle("navigation")
);

const store = useGlobalStore()

</script>

<style lang="scss">
#vertical-nav {
	position: fixed;
	background: rgba(var(--color-pure-white_rgb), 0.9);
	backdrop-filter: blur(5px);
	z-index: 10;
	top: 120px;
	right: var(--base-padding-x);
	opacity: 0;
	transform: translate(0, 200px) rotate(-30deg);
	padding: 30px;
	border-radius: 10px;
	will-change: transform;
	transition: transform cubic-bezier(.35, 0, 0, 1) .8s, opacity cubic-bezier(.35, 0, 0, 1) .2s;
	color: var(--color-black);
	box-shadow: var(--shadow-drop);
	pointer-events: none;

	&.active {
		transform: translate(0, 0) rotate(0deg);
		;
		opacity: 1;
		transition: transform cubic-bezier(.35, 0, 0, 1) .3s, opacity cubic-bezier(.35, 0, 0, 1) .3s;
		color: var(--color-black);
		pointer-events: all;
	}

	ul {
		list-style: none;
		text-transform: uppercase;
		padding: 0px;

		a {
			color: var(--color-black);
			text-decoration: none;

			&:hover {
				color: var(--color-white);
			}

			&.router-link-active:hover {
				color: var(--color-black);
				cursor: default;
			}
		}

		&.services {
			font-size: 16px;
			line-height: 36px;
			margin-bottom: 30px;
		}

		&.pages {
			font-size: 14px;
			line-height: 24px;
			text-transform: none;

			a:hover {
				color: var(--color-primary);
			}
		}
	}
}
</style>
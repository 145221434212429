<template>
  <button @mouseenter="soundStore.playHover" @click="startAnim" class="btn">
    <svg ref="svg" class="icon" viewBox="0 0 45 45" fill="none">
      <polyline id="wave" ref="wave" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>
</template>

<script setup>
  import { gsap } from 'gsap'
  import { CustomEase } from 'gsap/CustomEase';
  gsap.registerPlugin(CustomEase);

  const gtm = useGtm()
  
  const soundStore = useSoundStore()

  const svg = ref()
  const wave = ref()
  
  var width = 48;
  var sinus = CustomEase.create("sinus", "M0,0 C0.4,0 0.3,1 0.5,1 0.7,1 0.6,0 1,0");

  var amplitude = 40;
  var frequency = 6;
  var segments  = 50;
  var interval  = width / segments;
  
  var tweens = [];

  gsap.defaults({
    ease: "sine.inOut"
  });
  
  onMounted(() => {

    for (var i = 0; i <= segments; i++) {
    
      var norm = 1 - i / segments;
      var point = wave.value.points.appendItem(svg.value.createSVGPoint());
      
      point.x = i * interval;
      point.y = amplitude / 2 * sinus(norm);
        
      var tween = gsap.to(point, { 
        duration: 0.3,
        y: -point.y, 
        repeat: -1, 
        yoyo: true,
        paused: true
      }).progress(norm * frequency);  

      tweens.push(tween);
      gsap.to(wave.value.points, { duration: .2, y:0 });
    }
  });

  function startAnim() {

    if(soundStore.soundActive === true) {

      gtm.trackEvent({
        event: 'interaction',
        category: 'Sound Button',
        action: 'click',
        label: 'Sound Off'
      })
      
      tweens.forEach(tween => tween.pause());
      gsap.to(wave.value.points, { duration: .2, y:0 });
      soundStore.playClose()
      soundStore.pauseMusic()
      soundStore.closeSound()
      
    } else {

      gtm.trackEvent({
        event: 'interaction',
        category: 'Sound Button',
        action: 'click',
        label: 'Sound On'
      })
      
      tweens.forEach(tween => tween.play());
      soundStore.openSound()
      soundStore.playClick()
      soundStore.playMusic()
    
    }
  }
</script>
<template>
	<section class="container">

		<div class="home-hero grid vcenter">
			<div class="half">
				<AMARLogo id="main-logo" />
			</div>

			<div class="half acenter">
				<h1 class="header1">Ooops!</h1>
				<h2 class="header3">We nearly lost you...</h2>
				<p>{{ error?.statusCode }} - {{ error?.statusMessage }}</p><br /><br />

				<ButtonsAction class="btn" :action="handleError" :label="'Back to Safety'"></ButtonsAction>
			</div>
		</div>
		<Background />

	</section>
</template>

<script setup>
import AMARLogo from '~/assets/svg/AMAR_Logo.svg?component'

const props = defineProps(['error'])

const handleError = () => {
	clearError({ redirect: '/' })
}
</script>

<style lang="scss" scoped>
.home-hero {
	height: calc(100vh - 180px);

	#main-logo {
		fill: var(--color-white);
		filter: drop-shadow(0px 40px 50px rgba(var(--color-pure-black_rgb), 0.3));
	}
}
</style>

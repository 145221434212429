import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
	state: () => ({
		menuOpen: false,
		calendar: false,
	}),

	actions: {
		openNav() {
			this.menuOpen = true
		},
		closeNav() {
			this.menuOpen = false
		},

		openCalendar() {
			document.body.width = document.body.clientWidth;
			document.body.classList.toggle('noscroll', true);
			this.calendar = true
		},
		closeCalendar() {
			document.body.classList.toggle('noscroll', false);
			this.calendar = false
		},
	},
})